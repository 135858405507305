<template>
  <div>
    <v-card class="d-flex justify-end mb-6" flat tile>
      <v-breadcrumbs :items="breadcrumbs" large>
        <template v-slot:divider>
          <v-icon>{{ icons.mdiSlashForward }} </v-icon>
        </template>
      </v-breadcrumbs>
    </v-card>
    <v-card flat class="pa-3 mt-2">
      <v-card-text>
        <v-card-title class="text-no-wrap pt-1 ps-2"> EDIT COMPANY</v-card-title>
        <form @submit.prevent="CreateData" ref="form" enctype="multipart/form-data">
          <v-row>
            <v-col cols="12" v-if="errors.length">
              <v-alert color="error" v-for="error in errors" :key="error" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning"> </v-icon>
                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">{{ error }}</p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field label="Company Name" v-model="companys.nama_perusahaan" dense outlined></v-text-field>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field label="Director Name" v-model="companys.nama_pimpinan" dense outlined></v-text-field>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field label="Address" v-model="companys.alamat_perusahaan" dense outlined></v-text-field>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field label="Email" v-model="companys.email" dense outlined></v-text-field>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field label="Phone 01" v-model="companys.telp_01" dense outlined></v-text-field>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field label="Phone 02" v-model="companys.telp_02" dense outlined></v-text-field>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field label="Phone 03" v-model="companys.telp_03" dense outlined></v-text-field>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field label="Phone 04" v-model="companys.telp_04" dense outlined></v-text-field>
            </v-col>

            <v-col md="6" cols="12">
              <v-card-text class="text-no-wrap pt-1 ps-1"> Logo</v-card-text>
              <!-- <v-img :lazy-src="showPhoto()" max-height="150" max-width="150" :src="showPhoto()"></v-img> -->
              <div class="cropper-area">
                <div class="img-cropper">
                  <vue-cropper
                    ref="cropper"
                    :key="showPhoto"
                    :aspect-ratio="4 / 2"
                    :src="showPhoto()"
                    preview=".preview"
                  />
                </div>
              </div>

              <v-btn type="submit" color="primary" @click.prevent="showFileChooser" class="me-3 mt-4">
                Choose Image
              </v-btn>
              <v-btn type="submit" color="primary" @click.prevent="cropImage" class="me-3 mt-4"> Crop </v-btn>

              <input style="display: none" ref="input" type="file" accept="image/*" @change="setImage" />
              <br />
              <br />
              <v-card-text class="text-no-wrap pt-1 ps-1"> Crop Logo</v-card-text>
              <section class="preview-area">
                <!-- <p>Cover</p> -->
                <div class="cropped-image">
                  <img width="200px" :src="updatePhoto()" />
                  <!-- <img width="200px" :src="clients.photo == '' ? defautlPhoto() : clients.photo" alt="Cropped Image" /> -->
                </div>
              </section>
            </v-col>

            <v-col cols="12">
              <v-btn type="submit" color="primary" class="me-3 mt-4"> Update </v-btn>

              <router-link :to="{ name: 'companys-index' }">
                <v-btn color="secondary" outlined class="mt-4" type="reset"> Cancel </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </form>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mdiCheckBold, mdiSlashForward } from '@mdi/js'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
export default {
  components: {
    VueCropper,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/admin/dashboard',
        },
        {
          text: 'Companys',
          disabled: false,
          href: '/admin/companys',
        },
        {
          text: 'Update Companys',
          disabled: true,
        },
      ],

      icons: {
        mdiCheckBold,
        mdiSlashForward,
      },

      companys: {
        nama_perusahaan: '',
        nama_pimpinan: '',
        alamat_perusahaan: '',
        email: '',
        telp_01: '',
        telp_02: '',
        telp_03: '',
        telp_04: '',
        logo_perusahaan: '',
      },
      errors: [],
    }
  },
  mounted() {
    this.$isLoading(true)
    setTimeout(() => {
      // this.getAllRoles()
      this.getAllCompany()
      this.showPhoto()
      this.$isLoading(false)
    }, 2000)
  },
  methods: {
    getAllCompany() {
      // let uri = `/api/admin/roles/${this.$route.params.id_role}/show`
      let uriCompany = process.env.VUE_APP_ROOT_API + `/api/pemiliks/${this.$route.params.id_pemilik}/show`
      this.axios.get(uriCompany).then(response => {
        this.companys = response.data.pemilik
        console.log(this.companys.email)
      })
    },

    showPhoto() {
      if (this.companys.logo_perusahaan.length != 0) {
        if (this.companys.logo_perusahaan.length > 100) {
          return this.companys.logo_perusahaan
        }
        let imageClients = process.env.VUE_APP_ROOT_API + `/images/logo_perusahaan/${this.companys.logo_perusahaan}`
        return imageClients
      }
      let imageDefault = process.env.VUE_APP_ROOT_API + `/images/logo_perusahaan/no-images.png`
      return imageDefault
    },

    updatePhoto() {
      if (this.companys.logo_perusahaan == null || this.companys.logo_perusahaan == '') {
        // return "../../../../images/guru/no-image.jpg";
        let imageDefault = process.env.VUE_APP_ROOT_API + `/images/logo_perusahaan/no-images.png`
        return imageDefault
      }

      let img = this.companys.logo_perusahaan

      if (img.length > 100) {
        return this.companys.logo_perusahaan
      } else {
        let imageClients = process.env.VUE_APP_ROOT_API + `/images/logo_perusahaan/${this.companys.logo_perusahaan}`
        return imageClients
      }
    },

    setImage(e) {
      const file = e.target.files[0]
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file')
        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = event => {
          this.companys.logo_perusahaan = event.target.result
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result)
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.companys.logo_perusahaan = this.$refs.cropper.getCroppedCanvas().toDataURL()
    },
    showFileChooser() {
      this.$refs.input.click()
    },

    CreateData(e) {
      // console.log('ID_ROLE: ' + this.users.id_role)

      if (this.$data.companys.nama_perusahaan.length === 0) {
        // this.errors.push('Nama Role harus diisi !')
        this.$swal.fire({
          title: 'Error',
          text: 'Nama perusahaan wajib diisi !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else if (this.$data.companys.nama_pimpinan.length === 0) {
        // this.errors.push('Email harus diisi !')
        this.$swal.fire({
          title: 'Error',
          text: 'Nama pimpinan wajib diisi !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else if (this.$data.companys.alamat_perusahaan.length === 0) {
        // this.errors.push('Email harus diisi !')
        this.$swal.fire({
          title: 'Error',
          text: 'Alamat perusahaan wajib diisi !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else if (this.$data.companys.email.length === 0) {
        // this.errors.push('Email harus diisi !')
        this.$swal.fire({
          title: 'Error',
          text: 'Email perusahaan wajib diisi !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else if (this.$data.companys.telp_01.length === 0) {
        // this.errors.push('Email harus diisi !')
        this.$swal.fire({
          title: 'Error',
          text: 'Nomor Telp wajib diisi !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else {
        let uri = process.env.VUE_APP_ROOT_API + `/api/pemiliks/${this.$route.params.id_pemilik}`
        this.axios
          .post(uri, this.companys)
          .then(response => {
            this.$swal.fire({
              title: 'Success',
              text: 'Company update successfully',
              icon: 'success',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 1000,
            })

            // this.$router.push({ name: 'users-index' })
          })
          .catch(e => {
            // this.$swal.fire({
            //   title: 'Error!',
            //   html: this.users.email + ' sudah digunakan !',
            //   icon: 'error',
            //   showCancelButton: false,
            //   showConfirmButton: false,
            //   timer: 2000,
            // })
          })

        e.preventDefault()
        setTimeout(() => {
          this.$router.push({ name: 'companys-index' })
        }, 3000)
      }
    },
  },
}
</script>
